import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import {
    Checkbox, FormControlLabel, Snackbar, Grid, TextField, createStyles,
    withStyles,
    makeStyles, Container
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useForm, Controller } from 'react-hook-form'
import Master from '../components/Master'
import Button from '../ui/Button'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const defaultValues = {
    name: "",
    email: "",
    telephone: "",
    message: "",
    newsletter: true
}

const Contato = () => {

    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        newsletter: true,
    })

    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('')

    const snackbar = (message, severity) => {
        setMessage(message)
        setSeverity(severity)
        setOpen(true)
    }

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }
    const methods = useForm()
    const { handleSubmit, control, reset } = methods
    const onSubmit = data => {
        const graphqlQuery = {
            query: `
            mutation FormContact ($name: String!, $email: String!, $telephone: String!, $message: String!, $newsletter: Boolean!){
              formContact(
                  formContactInput:{
                      name: $name
                      email: $email
                      telephone: $telephone
                      message: $message
                      newsletter: $newsletter
                  }
              )
          }
          `,
            variables: {
                name: data.name ? data.name : "",
                email: data.email ? data.email : "",
                telephone: data.telephone ? data.telephone : "",
                message: data.message ? data.message : "",
                newsletter: data.newsletter
            }
        }

        fetch(`${process.env.API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(graphqlQuery)
        })
            .then(res => {
                return res.json()
            })
            .then(resData => {
                if (resData.errors) {
                    if (resData.errors[0].status === 422) {
                        if (resData.errors[0].data[0].message === 'Name cannot be null.') {
                            snackbar('Preencha o campo Nome.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'Email is invalid.') {
                            snackbar('Preencha o campo Email com um email válido.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'Email cannot be null.') {
                            snackbar('Preencha o campo Email.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'Telephone cannot be null.') {
                            snackbar('Preencha o campo WhatsApp / Telefone.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'Message cannot be null.') {
                            snackbar('Preencha o campo Mensagem.', 'error')
                        }
                        else {
                            snackbar(resData.errors[0].data[0].message, 'error')
                        }
                    }
                }
                else {
                    snackbar('Formulário enviado com sucesso!', 'success')
                    reset({ defaultValues })
                }
            })
            .catch(err => {
                snackbar('Erro ao enviar formulário, verifique todos os campos e tente novamente.', 'error')
            })

    };

    const data = useStaticQuery(graphql`
        query {
            page(id: {eq: "5efb7c73d430990d8832f291"}) {
                title
                description
                keywords
                details
            }
            BUTTON_BACKGROUND_COLOR: setting(environmentName: {eq: "BUTTON_BACKGROUND_COLOR"}) {
                value
            }
            MAIN_BACKGROUND_COLOR_COMPLEMENT: setting(environmentName: {eq: "MAIN_BACKGROUND_COLOR_COMPLEMENT"}) {
                value
            }
        }
    `)
    const page = data.page
    const BUTTON_BACKGROUND_COLOR = data.BUTTON_BACKGROUND_COLOR.value
    const MAIN_BACKGROUND_COLOR_COMPLEMENT = data.MAIN_BACKGROUND_COLOR_COMPLEMENT.value

    const { newsletter } = state

    const CssTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: `${BUTTON_BACKGROUND_COLOR}`
            },
            '& label': {
                fontFamily: `${process.env.FONT_FAMILY}`,
                fontSize: `${process.env.FONT_SIZE}`
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: `${MAIN_BACKGROUND_COLOR_COMPLEMENT}`
            },
            '& .MuiInputBase-input': {
                fontFamily: `${process.env.FONT_FAMILY}`,
                fontSize: `${process.env.FONT_SIZE}`
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: `${MAIN_BACKGROUND_COLOR_COMPLEMENT}`,
                },
                '&:hover fieldset': {
                    borderColor: `${MAIN_BACKGROUND_COLOR_COMPLEMENT}`,
                },
                '&.Mui-focused fieldset': {
                    borderColor: `${MAIN_BACKGROUND_COLOR_COMPLEMENT}`,
                }
            },
        },
    })(TextField)

    const CssFormControlLabel = withStyles({
        root: {
            '& .MuiTypography-body1': {
                fontFamily: `${process.env.FONT_FAMILY}`,
                fontSize: `${process.env.FONT_SIZE}`
            }
        },
    })(FormControlLabel)

    const useStyles = makeStyles(theme =>
        createStyles({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            center: {
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
            },
            width: {
                width: '100%'
            },
            imagePaper: {
                width: '100%',
                marginTop: '10px',
                borderRadius: '4px'
            },
        }),
    )

    const classes = useStyles()

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}/contato`}
                noindex={true}
                nofollow={true}
                openGraph={{
                    url: `${process.env.SITE_URL}/contato`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Container maxWidth="md" component="main" className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.center}>{page.title}</h1>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <img src={`https://firebasestorage.googleapis.com/v0/b/indall.appspot.com/o/images%2Flayout%2Fcontato.jpg?alt=media&token=ebc6c9f0-e671-4af1-87b0-f06c5b384d26`} className={classes.imagePaper} title={page.title} alt={page.title} />
                        <div dangerouslySetInnerHTML={{ __html: page.details }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller as={CssTextField} name="name" control={control} label="Nome" className={classes.width} />
                            <Controller as={CssTextField} name="email" control={control} label="Email" className={classes.width} />
                            <Controller as={CssTextField} name="telephone" control={control} label="WhatsApp ou Telefone" className={classes.width} />
                            <Controller as={CssTextField} name="message" control={control} label="Mensagem" className={classes.width} multiline={true} rows={5} />
                            <Controller
                                as={<CssFormControlLabel
                                    onChange={handleChange('newsletter')} checked={newsletter}
                                    control={<Checkbox color="primary" name="newsletter" style={{ color: `${BUTTON_BACKGROUND_COLOR}` }} />}
                                    label="Desejo receber dicas e novidades"
                                    labelPlacement="end"
                                />}
                                name="newsletter"
                                value="newsletter"
                                control={control}
                                defaultValue={true}
                            />
                            <br />
                            <Button>
                                Enviar
                        </Button>
                        </form>
                    </Grid>
                </Grid>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
        </Master>
    )
}

export default Contato